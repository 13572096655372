import * as React from "react";
import {
  Datagrid,
  DateField,
  Filter,
  List,
  Pagination,
  TextField,
  TextInput,
} from 'react-admin';
import { Route } from 'react-router';
import { withRouter } from "react-router-dom";
import { Drawer } from '@material-ui/core';
import UserEdit from './UserEdit';

const DEFAULT_LIST_SORT = { field: 'creation_datetime', order: 'DESC' }

const UsersPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const UsersList = withRouter(props => (
  <>
    <List
      {...props}
      bulkActionButtons={false}
      filters={<Filter {...props}>
        <TextInput source="email" reference="users" label="email" />
      </Filter>}
      sort={DEFAULT_LIST_SORT}
      pagination={<UsersPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField source="email" label="email" />
            <DateField source="creation_datetime" label="Data criação" showTime style={{ fontSize: 10 }} />
        </Datagrid>
    </List>
    <Route path="/users/:id">
      {({ match }) => {
        const isMatch = !!(match && match.params && match.params.id !== "create");

        return (
          <Drawer open={isMatch} anchor="right" onClose={() => {props.history.goBack()}}>
            {isMatch ? (
              <UserEdit
                id={match.params.id}
                onCancel={() => {props.history.goBack()}}
                {...props}
              />
            ) : null}
          </Drawer>
        );
      }}
    </Route>
  </>
));

export default UsersList