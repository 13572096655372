import * as React from "react";
import {
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import {
  STATUS_VALUES,
  REASON_VALUES,
  APPROVED_STATUS_VALUES,
  REFUSED_STATUS_VALUES,
} from '../utils/constants';


const ToolbarWithoutDeleteButton = props => {
  return (
    <Toolbar {...props}>
        <SaveButton disabled={props.errors && Object.keys(props.errors).length > 0} />
    </Toolbar>
  )
};

const validateVideoEdit = (values, errState, setErrors) => {
  const hasErrorInState = errState && Object.keys(errState).length > 0
  const errors = {};
  const { status, refusal_reason } = values
  const isVideoApproved = APPROVED_STATUS_VALUES[status]
  const isVideoReproved = REFUSED_STATUS_VALUES[status]
  const isReasonEmpty = !refusal_reason

  if (isVideoReproved && isReasonEmpty) {
    errors.refusal_reason = ['validation.error.reprove-reason']
    errors.status = ['validation.error.reprove-reason']
    if (!hasErrorInState) {
      setErrors(errors)
    }
  } else if (isVideoApproved && !isReasonEmpty) {
    errors.refusal_reason = ['validation.error.approve-reason']
    errors.status = ['validation.error.approve-reason']
    if (!hasErrorInState) {
      setErrors(errors)
    }
  } else {
    if (hasErrorInState) {
      setErrors(null)
    }
  }

  return errors
};

const VideoEdit = (props) => {
  const [errors, setErrors] = React.useState(null);
  return (
    <Edit {...props}>
        <SimpleForm
          toolbar={<ToolbarWithoutDeleteButton errors={errors} />}
          validate={values => validateVideoEdit(values, errors, setErrors)}
        >
            <TextInput disabled source="id" />
            <TextInput disabled source="ad_id" />
            <SelectInput
              key={`state-select-${JSON.stringify(errors)}`}
              optionText="label"
              optionValue="value"
              label="status"
              source="status"
              reference="videos"
              emptyValue={null}
              emptyText="Todos"
              error={!!(errors && errors.status)}
              helperText={errors && errors.status && errors.status[0]}
              alwaysOn
              choices={Object.keys(STATUS_VALUES).map(status => ({
                label: STATUS_VALUES[status],
                value: status,
              }))}
            />
            <SelectInput
              optionText="label"
              optionValue="value"
              label="razão da reprovação"
              source="refusal_reason"
              reference="videos"
              error={!!(errors && errors.refusal_reason)}
              helperText={errors && errors.refusal_reason && errors.refusal_reason[0]}
              allowEmpty
              emptyValue={null}
              alwaysOn
              choices={Object.keys(REASON_VALUES).map(reason => ({
                label: REASON_VALUES[reason],
                value: reason,
              }))}
            />
            <TextInput source="url" />
        </SimpleForm>
    </Edit>
  )
};

export default VideoEdit