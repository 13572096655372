import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import portugueseMessages from '@henriko/ra-language-portuguese';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import jsonServerProvider from './utils/dataprovider';
import customAuthProvider from './utils/authprovider';
import fetcher from './utils/client';
import VideosList from './components/VideosList';
import UsersList from './components/UsersList';
import UserCreate from './components/UserCreate';
import Layout from './components/Layout';

const API_URL_FALLBACK = 'http://localhost:5000';
const API_URL = process.env.REACT_APP_API_ENDPOINT || API_URL_FALLBACK;
const PASSWORD_ENCRYPTION_KEY = process.env.REACT_APP_PWD_ENCRYPTION_KEY;
const dataProvider = jsonServerProvider(API_URL, fetcher);
const messages = {
  pt: {
    ...portugueseMessages,
    'success.bulk.approve': 'Videos aprovados com sucesso',
    'fail.bulk.approve': 'Erro ao aprovar videos',
    'success.bulk.refusal': 'Vídeos reprovados com sucesso',
    'fail.bulk.refusal': 'Erro ao reprovar vídeos',
    'validation.error.reprove-reason': 'Anúncios reprovados deveriam ter razão de reprovação',
    'validation.error.approve-reason': 'Anúncios aprovados não deveriam ter razão de reprovação',
    'ra.action.unselect': '', // this prevents some anoying wranings on console
    'login.error.http': 'Erro ao chamar servidor de login',
    'login.error.pwd': 'Senha incorreta',
    'login.error.email': 'Esse usuário não existe',
  },
};
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "pt");
const authProvider = customAuthProvider(API_URL, PASSWORD_ENCRYPTION_KEY);

const App = () => {
  return (
    <Admin
      dataProvider={dataProvider}
      layout={Layout}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
    >
        <Resource
          name="videos"
          list={VideosList}
        />
        <Resource
          name="users"
          list={UsersList}
          create={UserCreate}
        />
    </Admin>
  );
};

export default App;