import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
} from 'react-admin';

const UserEdit = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="email" />
        <TextInput source="pass" />
      </SimpleForm>
    </Create>
  )
};

export default UserEdit