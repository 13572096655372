import * as React from "react";
import {
  Edit,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';

// deletar usuarios vai ficar como debito, tem que implementar a rota no back
const ToolbarWithoutDeleteButton = props => {
  return (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
  )
};

const UserEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm
        toolbar={<ToolbarWithoutDeleteButton />}
      >
        <TextInput source="email" />
        <TextInput source="pass" />
      </SimpleForm>
    </Edit>
  )
};

export default UserEdit