import * as React from "react";
import {
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import { Button, MenuItem, Select } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { OLX_ORANGE, OLX_WHITE, OLX_GRAY } from '../styles/theme';
import { REASON_VALUES } from '../utils/constants';

const VideosBulkActionButtons = props => {
  const [reproveReason, setReproveReason] = React.useState(null)
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  const [aprovaTodes, { aproveLoading }] = useUpdateMany(
    'videos',
    props.selectedIds,
    { status: 'human_approved', refusal_reason: null },
    {
        onSuccess: (response) => {
          if(response.status === 200){
              refresh();
              notify('success.bulk.approve');
              unselectAll('videos');
          }else{
            notify('fail.bulk.approve', 'warning')
          }
        },
        onFailure: error => notify('fail.bulk.approve', 'warning'),
    }
  );
  // console.log('error', error);
  const [reprovaTodes, { refuseLoading }] = useUpdateMany(
    'videos',
    props.selectedIds,
    { status: 'human_reproved', refusal_reason: reproveReason },
    {
        onSuccess: (response) => {
          if(response.status === 200){
            refresh();
            notify('success.bulk.refusal');
            unselectAll('videos');
          }else{
            notify('fail.bulk.refusal', 'warning')
          }
        },
        onFailure: error => notify('fail.bulk.refusal', 'warning'),
    }
  );
  const loading = aproveLoading || refuseLoading
  return(
    <>
      <Button
        variant="contained"
        onClick={aprovaTodes}
        disabled={loading}
        startIcon={<ThumbUpIcon />}
        style={{
          color: OLX_WHITE,
          backgroundColor: loading ? OLX_GRAY : OLX_ORANGE,
        }}
      >
        Aprovar Todos
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={reprovaTodes}
        disabled={loading || !reproveReason}
        startIcon={<ThumbDownIcon />}
        style={{
          marginLeft: 13,
          color: OLX_WHITE,
          backgroundColor: loading || !reproveReason ? OLX_GRAY : OLX_ORANGE,
        }}
      >
        Reprovar Todos
      </Button>
      <Select
        id="bulk-reprove-reason-select"
        value={reproveReason}
        onChange={(event) => {
          setReproveReason(event.target.value);
        }}
        autoWidth
        displayEmpty
        style={{ marginLeft: 13 }}
      >
        <MenuItem key="reprove-choice-null" value={null}>
          para reprovar em massa escolha uma razão
        </MenuItem>
        {Object.keys(REASON_VALUES).map((choice, index) => {
          return (
            <MenuItem
              key={`reprove-choice-${index}`}
              value={choice}
            >
              {REASON_VALUES[choice] || 'para reprovar em massa escolha uma razão'}
            </MenuItem>
          )
        })}
      </Select>
    </>
  )
};

export default VideosBulkActionButtons
