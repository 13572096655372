import { createMuiTheme } from '@material-ui/core/styles';

// colors from https://zeroheight.com/4vizr8cla/p/33c604-cores
// primarias
export const OLX_PURPLE = "#6E0AD6";
export const OLX_ORANGE = "#F28000";
export const OLX_WHITE = "#FFFFFF";
export const OLX_GRAPHITE = "#4A4A4A";
// secundarias
export const OLX_BG_GRAY = "#F6F6F6";
export const OLX_GRAY = "#D2D2D2";

export const theme = createMuiTheme({
  palette: {
      secondary: {
        main: OLX_PURPLE,
      },
      info: {
        main: OLX_ORANGE,
      },
      background: {
        default: OLX_BG_GRAY,
      },
      text: {
        primary: OLX_GRAPHITE,
      },
  },
  typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Arial',
          'sans-serif',
      ].join(','),
  },
  overrides: {
      MuiButton: { // override the styles of all instances of this component
          root: { // Name of the rule
              color: 'white', // Some CSS
          },
      },
  },
});