import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';
import CryptoJS from 'crypto-js';

const PASSWORD_CREATION_ENCRYPTION_KEY = process.env.REACT_APP_CREATION_ENCRYPTION_KEY;

// edita por resource os parametros a serem enviados no post de Ediçao de um doc
const getRealUpdateParams = (resource, data) => {
  if (resource === 'videos') {
    const { refusal_reason, status, url } = data
    const user_name = localStorage.getItem('username')
    return { refusal_reason, status, url, user_name }
  } else if (resource === 'users') {
    const { email, pass } = data
    const protectedPwd = hashPassword(pass);
    return { email, password: protectedPwd }
  } else {
      return data
  }
}

// edita por resource os parametros a serem enviados no post de Criação de um doc
const getRealCreateParams = (resource, data) => {
    if (resource === 'users') {
      const { email, pass } = data
      const protectedPwd = hashPassword(pass);
      return { email, password: protectedPwd }
    } else {
      return data
    }
  }

const hashPassword = (pass) => {
    const hashed = CryptoJS.AES.encrypt(pass, PASSWORD_CREATION_ENCRYPTION_KEY).toString();
    return hashed;
}

export default (apiUrl, httpClient): DataProvider => ({
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(async res => {
            const { headers } = res
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            const total = `${headers.get('x-total-count').split('/').pop()}`
            return {
                data: await res.json(),
                total: parseInt(total, 10),
            };
        });
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(async res => ({
            data: await res.json(),
        })),

    getMany: (resource, params) => {
        const query = {
            id: params.ids,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(async res => ({ data: await res.json() }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            ...fetchUtils.flattenObject(params.filter),
            [params.target]: params.id,
            _sort: field,
            _order: order,
            _start: (page - 1) * perPage,
            _end: page * perPage,
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(async res => {
            const { headers } = res
            if (!headers.has('x-total-count')) {
                throw new Error(
                    'The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?'
                );
            }
            return {
                data: await res.json(),
                total: parseInt(
                    headers
                        .get('x-total-count')
                        .split('/')
                        .pop(),
                    10
                ),
            };
        });
    },

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(getRealUpdateParams(resource, params.data)),
        }).then(async res => ({ data: await res.json() })),

    // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(async res => { 
            const data = await res.json()
            return data.id
        }) })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(getRealCreateParams(resource, params.data)),
        }).then(async res => {
            const data = await res.json()
            return { data: { ...params.data, id: data.id }}
        }),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(async res => ({ data: await res.json() })),

    // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(async res => {
            const data = await res.json()
            return data.id
        }) })),
});