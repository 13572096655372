import * as React from "react";
import { IconButton, Popover } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { OLX_ORANGE, OLX_WHITE } from '../styles/theme';
import PlayerVideo from './PlayerVideo';

const VideoPreview = ({ record }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [visible, showPopOver] = React.useState(false);
  return (
    <>
      <IconButton size="small" style={{ backgroundColor: OLX_ORANGE }} onClick={e => {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
        showPopOver(true)
      }}>
        <VisibilityIcon style={{ fontSize: 15, color: OLX_WHITE }} />
      </IconButton>
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={e => {e.stopPropagation()}}
        className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
        style={{ fontSize: 10, marginLeft: 5 }}
        href={record.url}
      >
          {record.url}
      </a>
      <Popover
        open={visible}
        onClose={() => showPopOver(false)}
        onClick={e => {e.stopPropagation()}}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div style={{ width: 548, height: 420, padding:"3px" }}>
          <PlayerVideo url={record.url}/>
        </div>
      </Popover>
    </>
  )
}

export default VideoPreview
