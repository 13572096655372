// The STATUS and REASON values should reflect the API contract
// if changed just update them here
export const STATUS_VALUES = {
  'pending': 'pendente',
  'human_approved': 'aprovado manualmente',
  'auto_approved': 'aprovado automaticamente',
  'human_reproved': 'reprovado manualmente',
  'auto_reproved': 'reprovado automaticamente',
}

export const REASON_VALUES = {
  'unavailable': 'indisponível',
  'private': 'privado',
  'too_short': 'muito curto',
  'too_long': 'muito longo',
  'low_quality': 'qualidade baixa',
  'repeated': 'repetido',
  'not_related_to_offer': 'não relacionado ao anúncio',
  'zero_tolerance': 'tolerância zero',
  'forbidden_items': 'itens proibidos',
  'unknown_host': 'URL inválida',
  'deleted': 'Vídeo deletado',
  'others': 'outros',
}

export const APPROVED_STATUS_VALUES = {
  'pending': false,
  'human_approved': true,
  'auto_approved': true,
  'human_reproved': false,
  'auto_reproved': false,
}

export const REFUSED_STATUS_VALUES = {
  'pending': false,
  'human_approved': false,
  'auto_approved': false,
  'human_reproved': true,
  'auto_reproved': true,
}