import * as React from "react";
const PLAYER_ENDPOINT = 'vx-player.olx.com.br'

const PlayerVideo = ({ url }) => {
  const encodedVideoUrl = encodeURIComponent(url)
  const playerURL = `https://${PLAYER_ENDPOINT}?url=${encodedVideoUrl}`
  console.log(playerURL)
  return (
    <iframe  title="video" src={playerURL} style={{width:540,height:410, overflow:"hidden", border:0}}/>
  )
}


export default PlayerVideo
