import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { OLX_BG_GRAY } from './styles/theme';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
// o tema nao alcansa essa camada, então por consistencia visual forcei isso aqui
document.body.style.backgroundColor = OLX_BG_GRAY;
