import * as React from "react";
import {
  Datagrid,
  DateField,
  List,
  Pagination,
  TextField,
} from 'react-admin';
import { Route } from 'react-router';
import { withRouter } from "react-router-dom";
import { Drawer } from '@material-ui/core';
import Filter from './Filter';
import VideoPreview from './VideoPreview';
import VideoEdit from './VideoEdit';
import VideosBulkActionButtons from './VideosBulkActionButtons';
import { STATUS_VALUES, REASON_VALUES } from '../utils/constants';

const DEFAULT_LIST_SORT = { field: 'creation_datetime', order: 'DESC' }

const VideosPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const StatusColumnField = props => <span>{STATUS_VALUES[props.record.status] || ''}</span>;
const ReasonColumnField = props => <span>{REASON_VALUES[props.record.refusal_reason] || ''}</span>;

const VideosList = withRouter(props => (
  <>
    <List
      {...props}
      filters={<Filter />}
      sort={DEFAULT_LIST_SORT}
      bulkActionButtons={<VideosBulkActionButtons />}
      pagination={<VideosPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField source="ad_id" label="AdId" />
            <TextField source="list_id" label="ListId" />
            <TextField source="account_id" label="AccountId" />
            <VideoPreview source="url" sortable={false} />
            <StatusColumnField source="status" label="Status" />
            <ReasonColumnField source="refusal_reason" label="Razão da reprovação" sortable={false} />
            <DateField source="creation_datetime" label="Data criação" showTime style={{ fontSize: 10 }} />
            <DateField source="modification_datetime" label="Ultima modificação" showTime style={{ fontSize: 10 }} />
        </Datagrid>
    </List>
    <Route path="/videos/:id">
      {({ match }) => {
        const isMatch = !!(match && match.params && match.params.id !== "create");

        return (
          <Drawer open={isMatch} anchor="right" onClose={() => {props.history.goBack()}}>
            {isMatch ? (
              <VideoEdit
                id={match.params.id}
                onCancel={() => {props.history.goBack()}}
                {...props}
              />
            ) : null}
          </Drawer>
        );
      }}
    </Route>
  </>
));

export default VideosList