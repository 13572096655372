import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Layout,
  Sidebar,
  setSidebarVisibility,
  AppBar,
  Menu,
} from 'react-admin';
import { theme } from '../styles/theme';

const CustomSidebar = (props) => {
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  return open ? <Sidebar {...props} /> : <div style={{ padding: 10 }} />
};

export default (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setSidebarVisibility(false));
    });

    return (
        <Layout
            {...props}
            appBar={AppBar}
            sidebar={CustomSidebar}
            menu={Menu}
            theme={theme}
        />
    );
};