const originalFetch = require('isomorphic-fetch');
const fetcher = require('fetch-retry')(originalFetch);

const DEFAULT_RETRIES = 5;
const DEFAULT_RETRY_DELAY = 420; // in miliseconds
const RETRY_ON = [  // fazer retry nesses status extras tbm
  503,
  500,
]

const fetcherWrapper = (url, options = {}) => {
  return fetcher(url, {
    ...options,
    retries: DEFAULT_RETRIES,
    retryDelay: DEFAULT_RETRY_DELAY,
    retryOn: RETRY_ON,
    headers: {
      ...(options.headers || {}),
      'Content-Type': 'application/json',
    },
  })
}

export default fetcherWrapper