import * as React from "react";
import { Filter, SelectInput, TextInput } from 'react-admin';
import { STATUS_VALUES } from '../utils/constants';

const VideoFilter = props => (
  <Filter {...props}>
    <SelectInput
      optionText="label"
      optionValue="value"
      label="status"
      source="status"
      reference="videos"
      allowEmpty
      emptyValue={null}
      emptyText="Todos"
      alwaysOn
      choices={Object.keys(STATUS_VALUES).map(status => ({
        label: STATUS_VALUES[status],
        value: status,
      }))}
    />
    <TextInput source="ad_id" reference="videos" label="ad id" />
    <TextInput source="list_id" reference="videos" label="list id" />
    <TextInput source="account_id" reference="videos" label="account id" />
    <TextInput source="url" reference="videos" label="URL" />
  </Filter>
);

export default VideoFilter