
import CryptoJS from 'crypto-js';

export default (apiUrl, pwdEcryptKey) => ({
  // AUTHENTICATION (identidade)
  // called when the user attempts to log in
  login: async (user) => {
      const GRAVAME = ''
      const pwdValue = pwdEcryptKey || Object.keys({ GRAVAME })[0]
      const userAcess = Object.keys(user).map((key) => user[key])
      const protectedPwd = CryptoJS.AES.encrypt(userAcess[1], pwdValue).toString();
      const message = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: userAcess[0],
          password: protectedPwd,
        })
      }).then(async res => {
        console.log({AUTH_STATUS: res.status})
        if (res.status === 404) {
          return 'login.error.email'
        }
        const confirmation = await res.text()
        if (confirmation === "senha incorreta") {
          return 'login.error.pwd'
        }
        // success
        localStorage.setItem('username', userAcess[0]);
        return null
      }).catch(err => {
        console.log('[AUTH] ERROR: ', err)
        return 'login.error.http'
      })
      if (message) {
        return Promise.reject({ message });
      }
      return Promise.resolve();
  },
  // called when the user clicks on the logout button
  logout: () => {
      localStorage.removeItem('username');
      return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
      if (status === 401 || status === 403) {
          localStorage.removeItem('username');
          return Promise.reject();
      }
      return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
      return localStorage.getItem('username')
          ? Promise.resolve()
          : Promise.reject();
  },
  getIdentity: () => {
    try {
        const username = localStorage.getItem('username');
        return Promise.resolve({
          id: username,
          fullName: username,
          avatar: "https://gitlab.olxbr.io/uploads/-/system/group/avatar/820/Screen_Shot_2020-09-10_at_12.52.36.png",
        });
    } catch (error) {
        return Promise.reject(error);
    }
  },
  // AUTHORIZATION (permissões)
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
});